#app {
    height: 100%;
  }
 html,

  body {
    position: relative;
    height: 100%;
  }
  
  body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  }
  
.sec-1 p {
  color: aqua;
}
section .animate {
  transition: 1s;
}
.sec-1 .animate {
  transform: translateX(-10%);
}
.sec-1 show.animate .animate {
  transform: translateX(10);
}

  .swiper {
    width: 100%;
    /* height: 100%; */
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
  }
  